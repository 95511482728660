import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextMotionDiv } from './TextMotionDiv';

export function FrontPageFooter(props) {            
        return (            
            <div>
            <Row className='fixed-bottom front-page-footer justify-content-center'>  
                <Col xl={5} lg={6} md={7} sm={10} xs={10} 
                className='mb-lg-5  mb-md-5 mb-sm-5 mb-4 ml-lg-5 ml-md-5 ml-sm-3 ml-3'>                    
                    <Row>
                        <Col>
                            <TextMotionDiv visible={props.content}
                            preset='fadeIn' whenScrolledIntoView>
                                <div dangerouslySetInnerHTML={{__html: props.content}}></div>
                            </TextMotionDiv>
                        </Col>
                    </Row>
                    <Row className='mt-xl-3 mt-lg-3 mt-md-4'>
                        <Col>
                        </Col>
                    </Row>                    
                </Col>                
                <style jsx>
                    {` 
                    .ad {
                        color: #FFF;
                        font-size: 0.5em;
                        opacity: 0.7;
                        z-index: 0;
                    }
                    .ad a {                        
                        text-decoration: underline;
                    }
                    .front-page-footer {
                        color: #FFF;
                        font-size: 1.5em;     
                        z-index: 0; 
                        text-shadow: 0.01rem 0.01rem 0.02rem #000;
                    }   
                    @media (max-width:575.98px) {
                        .front-page-footer {
                            font-size: 1.1em;
                        }
                    }
                    
                    @media(min-width: 576px) and (max-width:767.98px) {
                        .front-page-footer {
                            font-size: 1.1em;
                        }
                    }
                    
                    @media(min-width: 768px) and (max-width:991.98px){    
                        .front-page-footer {
                            font-size: 1.3em;
                        }
                    }
                    
                    @media(min-width: 992px) and (max-width:1199.98px){    
                        .front-page-footer {
                            font-size: 1.3em;
                        }
                    }
                    
                    @media(min-width:1200px) {
                        .front-page-footer {
                            font-size: 1.5em;
                        }
                    }              
                    `}
                </style>
            </Row>        
            <Row>
                <Col className='fixed-bottom ad text-center mb-2'>
                    WebDesign by <a target='_blank' href='https://coffeemugdev.de'>CoffeeMugDev</a>
                </Col>
            </Row>    
            </div>
        );
}