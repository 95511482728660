import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MenuCloseButtonWhiteIMG from './menu_close_button_white.svg';
import MenuCloseButtonBlackIMG from './menu_close_button_black.svg';
import AirBnBIMG from './airbnb.svg';

export class MobileMenu extends React.Component {            
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            socialMediaLinks: []       
        };                               
    }
    componentDidMount() {
        this.updateMenuPages();
        var WPAPI = require( 'wpapi' );
        var wp = new WPAPI({ endpoint: 'https://habitathabernis.de/wp/wp-json' });                    
        wp.socialMedia = wp.registerRoute('wp/v2', '/social_media');
        wp.socialMedia().get()
        .then(function(data) {
            let socialMediaLinks = [];            
            for (let socialMedia of data) {                
                socialMediaLinks.push(
                    <a href={socialMedia.url} target='_blank' rel='noopener noreferrer'>
                        <div key={socialMedia.url} className='social-media-boxx ml-4 mt-2'>                              
                            {
                                socialMedia.url.includes('airbnb') 
                                && <img className='social-media-box-image p-2' src={AirBnBIMG}></img>
                            }                        
                        </div>      
                    </a>
                );                
            }                    
            this.setState({
                socialMediaLinks: socialMediaLinks
            });          
        }.bind(this))
        .catch(function( err ) {
            console.log(err);
        }.bind(this));    
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pages.length !== this.props.pages.length) {            
            this.updateMenuPages();
        }
    }
    updateMenuPages() {
        let items = [];
        for (const page of this.props.pages) {   
            if (page.title.rendered !== 'Startseite') {                  
                items.push(                
                        <div onClick={this.menuButtonOnClickHandler.bind(this)} 
                        className='mobile-menu-item ml-4 my-4'>                        
                        <Link key={'a' + page.id} to={page.title.rendered === 'Ferienobjekte' 
                        ? '/rentals' : '/pages/' + page.id}>
                            {page.title.rendered}
                            </Link>
                        </div>                
                );                
            }
        } 
        this.setState({
            items: items
        });
    }
    menuButtonOnClickHandler() {
        window.scrollTo(0, 0);
        this.props.toggleMobileMenu();
    }
    render() {
        return (                
            <div className={'mobile-menu position-fixed ' 
            + (this.props.mobileMenuIsOpen ? 'open' : 'closed')}>  
            <Row className='mobile-menu-top'>                
                <Col className='mx-auto text-right'>                                 
                    <div className='close-button-background position-relative mr-4'>
                        <img className='close-button-image p-1' src={MenuCloseButtonWhiteIMG}></img>
                    </div>  
                    <a href='#'>
                        <div onClick={this.menuButtonOnClickHandler.bind(this)} 
                        className='close-button-container position-relative mr-4'>                        
                            <img className='close-button-image p-1' src={MenuCloseButtonBlackIMG}></img>
                        </div> 
                    </a>                                                        
                </Col>                
            </Row>                                  
            <Row className='mobile-menu-middle'>                                                                                                   
                <Col className='mobile-menu-list-container d-flex align-items-center'>                                         
                    <div className='mobile-menu-list ml-4'>                                            
                        {this.state.items}                    
                    </div>                                            
                </Col>                                
                <style jsx>
                    {`                       
                    .close-button-background {                        
                        z-index: 4;
                        top: 1.1em;
                        style: inline-block;
                    }
                    .close-button-container:hover {
                        opacity: 0;
                    }
                    .close-button-container {   
                        top: -1em;                                             
                        z-index: 5;
                        width: 2.2em;
                        height: 2.2em;
                        display: inline-block;
                        border-radius: 100%;                             
                        background-color: #f0f0f0;  
                        transition: opacity 0.2s ease;                      
                    }
                    .close-button-image {                          
                        width: 2.2em;
                        height: 2.2em;                 
                    }
                    .open {
                        right: 0em;
                        top: 0em;
                    }
                    .closed {
                        right: -19.1em !important;
                        top: 0em;
                    }
                    .mobile-menu {   
                        color: #FFF;  
                        font-family: 'Lato', sans-serif;
                        text-transform: uppercase;           
                        top: 0em;
                        right: 0em;                                       
                        width: 19em;
                        max-width: 100%;
                        background-color: #7b8d67;
                        height: 100%;      
                        z-index: 4;
                        transition: right 0.5s ease;             
                    }
                    .mobile-menu-item {                                                                                                                                                                   
                        letter-spacing: 0.08em;  
                        font-size: 1.3em;                        
                    }                   
                    .mobile-menu-top {
                        height: 5%;                        
                    }
                    .mobile-menu-middle {
                        height: 80%;                        
                    }
                    .mobile-menu-bottom {
                        height: 15%;  
                        letter-spacing: 0.09em;  
                        font-size: 0.8em;                                               
                    }
                    .social-media-boxx {
                        border-radius: 100%;
                        border: 1px solid #FFFFFF88;
                        display: inline-block;
                    }
                    .social-media-box-image {
                        width: 2.5em;
                        height: auto;                            
                    }                    
                    `}
                </style>            
            </Row>
            <Row className='mobile-menu-bottom '>
                <Col className='ml-4'>
                    <div className='ml-4'>
                        BLEIB AM START
                    </div>    
                    <a href='#'>                              
                        {this.state.socialMediaLinks}                        
                    </a>          
                </Col>
            </Row>  
            </div>                                                               
        );
    }
}

