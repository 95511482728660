import React, { Suspense, lazy } from 'react';
import './App.css';
import './bootstrap.min.css';
import { Spinner } from 'react-bootstrap';
import { MobileMenu } from './MobileMenu';
import { AnimatedSwitch } from 'react-router-transition';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import { FrontPage } from './FrontPage';

const PagePromise = import('./Page');
const Page = lazy(() => PagePromise);
const RentalsPromise = import('./Rentals');
const Rentals = lazy(() => RentalsPromise);
const RentalPagePromise = import('./RentalPage');
const RentalPage = lazy(() => RentalPagePromise);

export function makeContentResponsive(content) {  
  content = content.replace(/wp-image-/g, 'img-fluid my-2 wp-image-');
  content = content.replace(/aligncenter/g, 'text-center');
  content = content.replace(/has-text-align-center/g, 'text-center');
  content = content.replace(/alignright/g, 'text-right');
  content = content.replace(/has-text-align-right/g, 'text-right');
  content = content.replace(/alignleft/g, 'text-left');
  content = content.replace(/has-text-align-left/g, 'text-left');
  content = content.replace(/wp-block-embed-youtube/g, 'wp-block-embed-youtube embed-responsive embed-responsive-16by9');  
  var tempElement = document.createElement('html');
  tempElement.innerHTML = content;
  for (const gallery of tempElement.getElementsByClassName('wp-block-gallery')) {        
    for (const li of gallery.getElementsByTagName('li')) {
      li.setAttribute('class', li.getAttribute('class') + ' col-12 col-md-6 text-center')
    }    
    for (const img of gallery.getElementsByTagName('img')) {
      img.setAttribute('class', img.getAttribute('class') + ' rounded');
    }
    gallery.innerHTML = gallery.innerHTML.replace(/ul/g, 'div');    
    gallery.innerHTML = gallery.innerHTML.replace(/li/g, 'div');        
  }    
  content = tempElement.innerHTML.replace(/blocks-gallery-grid/g, 'blocks-gallery-grid row justify-content-center');      
  return content;
}

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      mobileMenuIsOpen: false      
    };
  }
  componentDidMount() {
    var WPAPI = require( 'wpapi' );
    console.log('loading pages...');
    var wp = new WPAPI({ endpoint: 'https://habitathabernis.de/wp/wp-json' });                    
    wp.pages().order('asc').orderby('menu_order').get()
    .then(function(data) {        
        this.setState({
            pages: data
        });
    }.bind(this))
    .catch(function( err ) {
        console.log(err);
    }.bind(this));    
  }  
  componentDidUpdate() {
    console.log('update');
  }
  toggleMobileMenu() {
    this.setState({
      mobileMenuIsOpen: !this.state.mobileMenuIsOpen
    });
  }
  render() {
    return (
      <Router>        
        <ScrollToTop></ScrollToTop>
        <div className="App">
          <head>        
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </head>      
          <body className='body'>            
            <div className={'position-fixed dark-overlay ' + (this.state.mobileMenuIsOpen ? 'visible ' : '')}>              
            </div>
            <div className='container-fluid'>          
              <MobileMenu toggleMobileMenu={this.toggleMobileMenu.bind(this)} 
              mobileMenuIsOpen={this.state.mobileMenuIsOpen} pages={this.state.pages}></MobileMenu> 
              {['', '/booking/'].map((route, index) =>
              <>
              <Route exact path={route + '/rentals/:rentalID'}>
                <Suspense fallback={null}>
                  <RentalPage toggleMobileMenu={this.toggleMobileMenu.bind(this)} 
                  pages={this.state.pages}></RentalPage>
                </Suspense>
              </Route>
              <Route exact path={route + '/rentals'}>                                
                <Suspense fallback={null}>
                  <Rentals toggleMobileMenu={this.toggleMobileMenu.bind(this)} 
                  pages={this.state.pages}></Rentals>
                </Suspense>
              </Route>
              <Route exact path={route + '/pages/:pageID'}>                                
                <Suspense fallback={null}>
                  <Page toggleMobileMenu={this.toggleMobileMenu.bind(this)} 
                  pages={this.state.pages}></Page>
                </Suspense>
              </Route>
              <Route exact path={route + '/'}>
                  <FrontPage pages={this.state.pages} toggleMobileMenu={this.toggleMobileMenu.bind(this)} pages={this.state.pages}></FrontPage>
              </Route>                                
              </>
              )}                 
            </div>             
            <style jsx>              
            {`
            .loadingProgress{              
              position: absolute;         
              left: 47%;
              top: 47%;                   
            }                                     
            .dark-overlay {
              width: 100%;
              height: 100%;
              background-color: #000;
              opacity: 0.0;
              visibility: hidden;
              z-index: 4;                         
              pointer-events: none;            
              transition: all 0.5s ease;
            }                       
            `}                        
            </style>                    
          </body>
        </div>        
      </Router>
    );
  }
}

export default App;
