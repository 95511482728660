import React from 'react';
import { SiteHeader } from './SiteHeader';
import { FrontPageFooter } from './FrontPageFooter';
import { Row, Col } from 'react-bootstrap';
import { TextMotionDiv } from './TextMotionDiv';

export class FrontPage extends React.Component {
    constructor(props) {
        super(props);     
        this.state = {            
            imageURL: '',
            backgroundImageLoaded: false
        };        
        this.backgroundImage = new Image();        
    }
    loadBackgroundImage() {            
        if (this.state.imageURL) {            
            this.backgroundImage.src = this.state.imageURL;
            this.backgroundImage.onload = () => this.setState({backgroundImageLoaded: true});        
        }
    }    
    componentDidMount() {
        console.log('front page mounted');
        this.updateContent();    
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pages.length !== this.props.pages.length) {
          this.updateContent();
        }
      }
    updateContent() {
        console.log('got pages');
        console.log(this.props.pages);            
        for (const page of this.props.pages) {
            if (page.title.rendered === 'Startseite') {
                let content = page.content.rendered;                                
                //content = content.replace(/<p/g, '<div')
                content = content.replace(/has-text-align-center/g, 'text-center');
                console.log(content);
                // .replace(/<\/?[^>]+(>|$)/g, "")
                this.setState({
                    content: content
                });                
                console.log(this.state.text);
                if (page.featured_media > 0) {  
                    var WPAPI = require('wpapi');
                    var wp = new WPAPI({ endpoint: 'https://habitathabernis.de/wp/wp-json' });      
                    wp.media().id(page.featured_media)
                    .then(function(data) {
                      this.setState({
                        imageURL: data.source_url
                      });
                      this.loadBackgroundImage();                      
                    }.bind(this))
                    .catch(function(err) {
                      console.log(err);
                    }.bind(this));
                } else {
                    this.setState({
                      imageURL: ''
                    });
                }
            }
        }           
    }
    render() {
        return (
            <div>
            <Row className='background'>                                                   
                    <div style={{
                        position: 'fixed',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        zIndex: -100,
                    }}>
                    <TextMotionDiv style={{height: '100%'}} visible={this.state.backgroundImageLoaded}
                    preset='fadeIn' whenScrolledIntoView style={{
                        backgroundImage: 'url("' + this.backgroundImage.src + '")',
                        position: 'absolute',
                        minWidth: '100%', 
                        height: '100%',                       
                        backgroundPosition: '35% 20%', 
                        backgroundSize: 'cover',
                        filter: 'brightness(60%)'
                    }}/>                    
                    </div>                
            </Row>                    
            <SiteHeader fadeInContent={this.props.fadeInContent} fadeOutContent={this.props.fadeOutContent} 
            toggleMobileMenu={this.props.toggleMobileMenu} pages={this.props.pages}></SiteHeader>                                        
            <FrontPageFooter content={this.state.content}></FrontPageFooter>                          
            </div>
        );
    }
}