import React from 'react';
import Radium from 'radium';
import { InView } from 'react-intersection-observer';

export class TextMotionDiv extends React.Component {
    constructor(props) {
        super(props);     
        this.state = {
            scrolledIntoViewport: false,
            isUnmounting: false,
            visible: false
        };        
    }   
    componentWillUnmount() {
        this.setState({isUnmounting: true});        
    } 

    componentDidUpdate(prevProps, prevState) {
        if (this.props.visible !== this.state.visible) {
            this.setState({visible: this.props.visible});
        }
    }
    componentDidMount() {
        this.setState({visible: this.props.visible});
    }
    render() {
        let shouldDisplay = this.state.visible && (!this.props.whenScrolledIntoViewport || this.state.scrolledIntoViewport)
        let animateOpacity = 1.0, initialOpacity = 1.0, animateTranslateY = '0rem',
        initialTranslateY = '0rem', exitOpacity = 1.0, exitTranslateY = '0rem';
        if (this.props.animate !== undefined) {
            if (this.props.animate.opacity !== undefined) {
                animateOpacity = this.props.animate.opacity;
            }
            if (this.props.animate.translateY !== undefined) {
                animateTranslateY = this.props.animate.translateY;
            }
        }        
        if (this.props.initial !== undefined) {
            if (this.props.initial.opacity !== undefined) {
                initialOpacity = this.props.initial.opacity;
            }
            if (this.props.initial.translateY !== undefined) {
                initialTranslateY = this.props.initial.translateY;
            }
        } 
        if (this.props.preset === 'fadeInUpwards') {
            initialOpacity = 0.0; 
            animateOpacity = 1.0;
            initialTranslateY = '2rem';
            animateTranslateY = '0rem';
            exitOpacity = 0.0;
            exitTranslateY = '0rem';        
        } else if (this.props.preset === 'fadeInDownwards') {
            initialOpacity = 0.0; 
            animateOpacity = 1.0;
            initialTranslateY = '-2rem';
            animateTranslateY = '0rem';
            exitOpacity = 0.0;
            exitTranslateY = '0rem';
        } else if (this.props.preset === 'fadeIn') {
            initialOpacity = 0.0; 
            animateOpacity = 1.0;
            initialTranslateY = '0rem';
            animateTranslateY = '0rem';
            exitOpacity = 0.0;
            exitTranslateY = '0rem';
        }    
        return (
            <>     
                <InView>{
                    function({inView, ref, entry}) {                        
                        if (inView && this.state.scrolledIntoViewport !== true) {
                            this.setState({scrolledIntoViewport: true});
                        }
                        return <div ref={ref}></div>;
                    }.bind(this)
                }</InView>           
                <div onClick={this.props.onClick} style={[{
                    transition: 'transform 1s ease, opacity 1s', 
                    opacity: this.state.isUnmounting ? exitOpacity 
                    : shouldDisplay ? animateOpacity : initialOpacity,
                    transform: this.state.isUnmounting ? exitTranslateY 
                    : shouldDisplay ? 'translateY(' + animateTranslateY +')' 
                    : 'translateY(' + initialTranslateY + ')',
                    //maxHeight: '0vh',
                    //overflowY: 'hidden'
                }, this.props.style]}>
                    {this.props.children}
                </div>
            </>
        );
    }
}

TextMotionDiv = Radium(TextMotionDiv);

var styles = {
    root: {
        display: 'flex',
        flex: 1
    }
};