import React from 'react';
import { SiteTitle } from './SiteTitle';
import { Row, Col } from 'react-bootstrap';
import { Menu } from './Menu'

export class SiteHeader extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            isScrolled: false
        };  
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }
    handleScroll(event) {
        if ((window.pageYOffset > 0 && !this.state.isScrolled) 
        || (window.pageYOffset == 0 && this.state.isScrolled)) {            
            this.setState({
                isScrolled: !this.state.isScrolled
            });
        }
    }
    render() {
        return (            
            <Row className={'site-header fixed-top ' 
            + (this.state.isScrolled ? 'scrolled ' : '' )}>                
                <SiteTitle dark={this.props.dark} minified={this.state.isScrolled}></SiteTitle>                
                <Menu fadeInContent={this.props.fadeInContent} fadeOutContent={this.props.fadeOutContent} 
                dark={this.props.dark} minified={this.state.isScrolled} toggleMobileMenu={this.props.toggleMobileMenu} pages={this.props.pages}></Menu>           
                         
                <style jsx>
                    {`                     
                    a {
                        text-decoration: none;
                        color: inherit;  
                    }                    
                    a:hover {
                        text-decoration: none;
                        color: inherit;
                    }                  
                    .site-header {
                        color: #FFF;                        
                        width: 10hv; 
                        z-index: 0;    
                        padding-top: 4vw; 
                        padding-bottom: 1vw;    
                        transition: padding-top 0.5s ease, padding-bottom 0.5s ease,
                            background-color 0.5s ease, margin-top 0.5s ease;
                    }              
                    .scrolled {                        
                        padding-top: 1.3vw !important;                             
                        padding-bottom: 1.8vw !important;
                        background-color: #656565 !important;                        
                        border-bottom: 1px solid #FeFeFe;                        
                    }             
                    `}
                </style>
            </Row>
        );
    }
}