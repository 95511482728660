import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TextMotionDiv } from './TextMotionDiv';
import LogoIMG from './logo.svg';

export class SiteTitle extends React.Component {    
    render() {
        return (                                               
            <Col xs={1} className='site-title text-left 
            mt-1 ml-lg-5 ml-md-5 ml-sm-3 ml-3'>
                <div className=''>                                        
                    <Link to='/'>   
                        <TextMotionDiv visible={LogoIMG}
                            preset='fadeIn' whenScrolledIntoViewport>
                            <img className={'logo-img ' 
                            + (this.props.minified ? 'logo-img-minified ' : '') 
                            + (this.props.dark ? 'logo-img-dark ' : '')}src={LogoIMG}></img>                        
                        </TextMotionDiv>
                    </Link>                    
                </div>                                 
                <style jsx>
                    {`                    
                    .logo-img {                        
                        width: 5em;
                        height: 5em;  
                        transition: filter 0.5s ease, width 0.5s ease, height 0.5s ease;                        
                    }                                        
                    .logo-img-dark:not(.logo-img-minified) {
                        filter: invert(100%);
                    }
                    .logo-img-minified {                        
                        width: 3em !important;
                        height: 3em !important;
                    }                       
                    .site-title {                        
                        font-family: 'Raleway', sans-serif;
                        text-transform: uppercase;                                                
                        font-weight: 600;
                        letter-spacing: 0.03em;
                        z-index: 1;
                    }
                    @media (max-width:575.98px) {
                        .site-title {
                            font-size: 1.0em;
                        }
                    }
                    
                    @media(min-width: 576px) and (max-width:767.98px) {
                        .site-title {
                            font-size: 1.0em;
                        }
                    }
                    
                    @media(min-width: 768px) and (max-width:991.98px){    
                        .site-title {
                            font-size: 1.1em;
                        }
                    }
                    
                    @media(min-width: 992px) and (max-width:1199.98px){    
                        .site-title {
                            font-size: 1.1em;
                        }
                    }
                    
                    @media(min-width:1200px) {
                        .site-title {
                            font-size: 1.3em;
                        }
                    }
                    `}
                </style>
            </Col>                
                
        );
    }
}