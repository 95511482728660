import React from 'react';
import { Col } from 'react-bootstrap';
import MenuButtonIMG from './menu_button.svg';
import { NavLink } from 'react-router-dom';
import { TextMotionDiv } from './TextMotionDiv';

export class Menu extends React.Component{
    constructor(props) {
        super(props);                             
        this.state = {
            items: []
        };
    } 
    componentDidMount() {
        this.updateContent();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pages.length !== this.props.pages.length) {            
            this.updateContent();
        }
    }
    updateContent() {
        let items = [];  
        for (const page of this.props.pages) {    
            if (page.title.rendered !== 'Startseite') {
                items.push(
                    <NavLink activeClassName='active-route'
                    key={'a' + page.id} to={page.title.rendered === 'Ferienobjekte' 
                    ? '/rentals' : '/pages/' + page.id}>
                        <div onClick={this.menuLinkClickHandler.bind(this)} 
                        key={'div' + page.id} 
                        className='menu-button mr-lg-5 mr-md-4 mr-sm-3'> 
                            {page.title.rendered}
                        </div>     
                    </NavLink>          
                );                                
            }
        }
        this.setState({
            items: items
        });
    }
    menuLinkClickHandler(event) {                
        window.scrollTo(0, 0);    
    }
    menuButtonOnClickHandler() {
        this.props.toggleMobileMenu();    
    } 
    render() {
        return (                                                
            <Col className={'mx-auto menu text-right text-md-center mt-md-4 mt-1 ' 
            + (this.props.minified ? 'minified ' : '')
            + (this.props.dark ? 'dark ' : 'menu-outline ')}>                
                <TextMotionDiv visible={this.state.item !== []}
                preset='fadeIn' whenScrolledIntoViewport>
                    <div className='d-none d-md-block'>                                           
                        {this.state.items}                                              
                    </div>   
                </TextMotionDiv>
                <a href='#'>
                    <div onClick={this.menuButtonOnClickHandler.bind(this)} className='d-block d-md-none'>
                        <img className={'mobile-menu-button-img mr-4 ' 
                        + (this.props.minified ? 'mobile-menu-button-img-minified mt-3 ' : '')
                        + (this.props.dark ? 'mobile-menu-button-img-dark' : '')} src={MenuButtonIMG}></img>
                    </div>                
                </a>
                <style jsx>
                    {` 
                    a {
                        display: inline-block;
                    }
                    .dark:not(.minified) {
                        color: #999999 !important;
                    }
                    .minified {                        
                        
                    }
                    .menu-button {                          
                        display: inline-block;
                        width: fit-content;
                        transition: color 0.5s ease;                        
                        letter-spacing: 0.1em;
                    }
                    .menu-button:hover {                             
                        opacity: 0.5;
                    }  
                    .dark.active-route:not(.minified) {
                        color: #000;
                    }
                    .active-route:not(.dark) {
                        color: #AAA;
                    }      
                    .mobile-menu-button-img {
                        width: 2.2em;
                    }
                    .mobile-menu-button-img-minified {                        
                        
                    }
                    .mobile-menu-button-img-dark:not(.mobile-menu-button-img-minified) {
                        filter: invert(60%);
                    }
                    .menu {    
                        z-index: 1000;
                        display: inline-block;                                                           
                        font-family: 'Lato', sans-serif;
                        text-transform: lowercase;
                        letter-spacing: 0.03em;  
                        transition: color 0.5s ease;                         
                    }
                    .menu-outline {
                        text-shadow: 0.01rem 0.01rem 0.02rem #000;
                    }
                    @media (max-width:575.98px) {
                        .menu {
                            font-size: 0.6em;
                        }
                    }
                    
                    @media(min-width: 576px) and (max-width:767.98px) {
                        .menu {
                            font-size: 0.8em;
                        }
                    }
                    
                    @media(min-width: 768px) and (max-width:991.98px){ 
                        .menu {
                            font-size: 0.8em;
                        }
                    }
                    
                    @media(min-width: 992px) and (max-width:1199.98px){   
                        .menu {
                            font-size: 0.8em;
                        }
                    }
                    
                    @media(min-width:1200px) {                    
                        .menu {
                            font-size: 0.9em;
                        }
                    }             
                    `}
                </style>
            </Col>                                                                      
        );   
    }
}